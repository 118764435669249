import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { getAdvancePaymentMethod } from "./redux/actions/loginActions";
import { setAdvanceCurrency } from "./redux/actions/currencyActions";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import CacheBuster from "./helpers/cacheBuster";

window.okStatus = 200;

export const store = createStore(
    rootReducer,
    load(),
    compose(applyMiddleware(thunk, save({ ignoreStates: ["featureProductData", "blogData", "shopData"] })))
);

if (process.env.NODE_ENV) {
  if (process.env.NODE_ENV === 'development') {
    //window.configEnv.WEBSTORESERVER = "https://nehal.aether-stores.io/public/"
    window.configEnv.WEBSTORESERVER = 'http://localhost:3333'
  }
}

axios.defaults.baseURL = window.configEnv.WEBSTORESERVER;
// fetch products from json file
//store.dispatch(fetchProducts(products));

if (localStorage.storeDetails) {
  store.dispatch({
    type: "STORE_DETAILS",
    payload: JSON.parse(localStorage.storeDetails)
  });
}
if (localStorage.advanceApiurl) {
  window.advanceApiurl = localStorage.advanceApiurl;
}


if (localStorage.advancePaymentMethod) {
  window.advancePaymentMethod = localStorage.advancePaymentMethod;
  store.dispatch(getAdvancePaymentMethod());
} else {
  store.dispatch(getAdvancePaymentMethod());
}


store.dispatch(setAdvanceCurrency());

ReactDOM.render(
    <Provider store={store}>
      <CacheBuster />
      <App />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
