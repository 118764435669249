import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import loginReducer from "./loginReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import blogsReducer from "./blogsReducer";
import featureProductReducer from "./featureProductReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import shopReducer from "./shopReducer";
import checkoutReducer from "./checkoutReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  userData: loginReducer,
  cartData: cartReducer,
  blogData: blogsReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  featureProductData: featureProductReducer,
  shopData: shopReducer,
  checkout: checkoutReducer
});

export default rootReducer;
