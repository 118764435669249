import React, { useReducer, createContext, useEffect, useState} from "react"
import axios from "axios"
import {
  SETTINGS, THEME_URL, PAYMENT_SETTING_URL,
  GET_USER_POINTS, GET_BANNER, X_TENANT_ID,
  CATEGORIES_URL, STATIC_PAGE_URL, ACTIONGRID,
  TESTIMONIAL, PROMOTIONALPRODUCT_CAT, IMAGESLIDER,
  CATEGORYSLIDER, GET_TAGS_URL, CATEGORIES_USER_URL,
  GET_USERGROUP_DISCOUNT, GET_CATEGORIES_BY_USER_GROUPS, GET_USER_BUDGET, SESSION, ADVANCE_STORE_DETAILS, FAVICON_REQUEST, IMAGES_REQUEST, BRAND_LOGOS, ARTWORK_USER_GROUP, USER_GROUP_DATA,
  DEFAULT_USERGROUP
} from './ActionUrl'
import * as ordersAPIUtil from "../util/order/ordersAPIUtil";
import * as productsApiUtil from "../util/products/productsAPIUtil";
import { updateUserIdCart } from "../util/cart/cartAPIUtil"
import { cleanContactData, setContactID } from "../redux/actions/loginActions";
import { updateUserIdWishlist } from "../util/wishlist/wishlistAPIUtil";
const publicIp = require("react-public-ip");

let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
let accessToken = localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : ""

const initialState = {
  setting: {},
  metaData: {},
  themeSettings: {},
  themeProductSettings: [],
  promotionalProductCat: {},
  bannersList: {},
  userPoints: {},
  imagesList: {},
  categorySliderList: {},
  actionGridList: [],
  decoTypeList: [],
  stateList: {},
  testimonialList: {},
  staticPagesList: [],
  categoriesList: [],
  categoriesMenuList: [],
  categoriesByUserGroup: [],
  isFirstTime: true,
  isPublic: true,
  enableLandingPage: false,
  landingPageRedirect : "",
  showAddToCartOnCat: true,
  tabName: '',
  storeDetails: [],
  storeStatus: 'Loading',
  isAllowSelfRegistration: '',
  isLPublic: true,
  isDirectToCart: '',
  isDisplayPrice: '',
  themedefaultColor: 'themedefaultColor',
  login: (user, isFirstTime = true) => {},
  logout: () => {},
  clanSession: () => {},
  callUserBudget: () => {},
  getClientLogo: () => {},
  updateUser: (user) => {},
  user: user,
  accessToken: accessToken,
  userType: user?.userType,
  loading: false,
  loadingCategories: false,
  userBudget: null,
  defaultUserGroup: null,
  userGroupDiscount: 0,
  usePointsAsCurrency: false,
  pointValue: 0,
  pointLabel: "Points",
  pointLabelUnit: "Point",
  showDecimalPoints: false,
  ipV4: null,
  ipV4Loaded: false,
  favicon: [],
  images: [],
  logoData: [],
  artworksByUserGroup: [],
  userGroupsData: [],
  googleCaptchaKey: '',
  tagsValues: {
    salesPerson: "",
    orderNumber: "",
    orderDate: "",
    orderStatus: "",
    orderInstructions: "",
    pointsUsed: "",
    points: "",
    pointsRemaining: "",
    websiteName: "",
    webStoreLink: "",
    webStoreName: "",
    storeCloseDate: "",
    customerEmail: "",
    customerName: "",
    customerPhone: "",
    customerContactInfo: "",
    customerContactAddress: "",
    supervisorEmail: "",
    cartItemsHtml: "",
    cartLink: "",
    userAccountLink: "",
    userEmail: "",
    productName: "",
    storeEmail: "",
    storePhoneNumber: "",
    productImage: "",
    productImageHtml: "",
    customSubject: "",
    customText: "",
    shippingCarrier: "",
    shippingMethod: "",
    supportEmail: "",
    total: "",
    code: "",
    userName: "",
    loadingUserCategories: undefined,
  },
}

const AuthContext = createContext(initialState)

function authReducer(state, action) {
  switch (action.type) {
    case 'SETTINGS':
      let tempIsPublic = ''
      let tempIsLandingPage = ''
      let tempLandingPageRedirect = ''
      let tempShowAddToCartOnCat = ''
      let tempShowAddToCartOnProd = ''
      let tempIsDisplayPrice = ''
      let tempTabName = ''
      let tempMetaTitle = ''
      let tempMetaDesc = ''
      let tempDirectToacart = ''
      let themedefaultColor = '#ff7f00'
      let tempChatSettings = ''
      if (action?.payload) {
        action.payload.map((i) => {
          if (i.page === 'General') {
            tempIsPublic = i.values.isPublic
            tempIsLandingPage = i.values.isLandingPageEnable
            tempLandingPageRedirect = i.values.landingPageRedirect
            tempShowAddToCartOnCat = i.values.showAddToCartOnCat
            tempShowAddToCartOnProd = i.values.showAddToCartOnProd
            tempIsDisplayPrice = i.values.isDisplayPrice
            tempTabName = i.values.tabName
            tempMetaTitle = i.values.metaTitle
            tempMetaDesc = i.values.metaDescription
            tempDirectToacart = i.values.isDirectToCart
            tempChatSettings = i.values.chatSettings
            if (
              i?.values?.themedefaultColor &&
              i.values.themedefaultColor !== ''
            ) {
              themedefaultColor = i.values.themedefaultColor
            }
          }
          if (i.page === 'GoogleApiSettings') {
            const googleApiToken = i.values?.googleApiToken
            const googleAuthClient = i.values?.googleClientId
            if(googleAuthClient) {
              localStorage.setItem('googleAuthClient', googleAuthClient)
            } else {
              if(localStorage.getItem('googleAuthClient')) {
                localStorage.removeItem('googleAuthClient')
              }
            }
            if (googleApiToken !== '')
              localStorage.setItem('googleApiToken', googleApiToken)
            else
              localStorage.setItem(
                'googleApiToken',
                'AIzaSyB2D8wrWMY3XZnuHO6C31uq90JiuaFzGws',
              )
          }
        })
      }
      return {
        ...state,
        isFirstTime: false,
        isPublic: tempIsPublic,
        enableLandingPage: tempIsLandingPage,
        landingPageRedirect: tempLandingPageRedirect,
        showAddToCartOnCat: tempShowAddToCartOnCat,
        showAddToCartOnProd: tempShowAddToCartOnProd,
        isDirectToCart: tempDirectToacart,
        isDisplayPrice: tempIsDisplayPrice,
        themedefaultColor: themedefaultColor,
        tabName: tempTabName,
        setting: action.payload,
        chatSettings: tempChatSettings,
        metaData: { title: tempMetaTitle, description: tempMetaDesc },
      }
    case 'BANNERLIST':
      return {
        ...state,
        bannersList: action.payload,
      }
    case 'USERGROUPDISCOUNT':
      return {
        ...state,
        userGroupDiscount: action.payload,
      }
    case 'USER_POINTS':
      return {
        ...state,
        userPoints: action.payload,
      }
    case 'PAYMENT_SETTINGS':
      return {
        ...state,
        paymentMethodsSettings: action.payload,
      }
      case 'POINT_CURRENCY_SETTINGS':
      return {
        ...state,
        usePointsAsCurrency: !!action.payload.useAsCurrency,
        pointValue: action.payload.pointValue,
        showDecimalPoints: action.payload.showDecimals,
        pointLabel: action.payload.pointLabel || "Points",
        pointLabelUnit: action.payload.pointLabelUnit || "Point",
      }
    case 'STORE_DETAILS':
      return {
        ...state,
        storeDetails: action.payload,
      }
    case 'STORE_STATUS':
      return {
        ...state,
        storeStatus: action.payload,
      }
    case 'ACTIONGRIDLIST':
      return {
        ...state,
        actionGridList: action.payload,
      }
    case 'DECOTYPELIST':
      return {
        ...state,
        decoTypeList: action.payload,
      }
    case 'STATELIST':
      return {
        ...state,
        stateList: action.payload,
      }

    case 'IMAGESLIST':
      return {
        ...state,
        imagesList: action.payload,
      }
    case 'CATEGORYSLIDERLIST':
      return {
        ...state,
        categorySliderList: action.payload,
      }

    case 'TESTIMONIALLIST':
      return {
        ...state,
        testimonialList: action.payload,
      }
    case 'PROMOTIONALPRODUCTCATLIST':
      return {
        ...state,
        promotionalProductCat: action.payload,
      }
    case 'THEME_SETTINGS':
      let tempIsAllowSelfRegistration = ''
      let tempLIsPublic = true
      if (action?.payload) {
        action.payload.map((i) => {
          if (i.settingsName === 'login') {
            tempIsAllowSelfRegistration = i.values[0].isAllowSelfRegistration
            tempLIsPublic = i.values[0].isPublic
          } else if (i.settingsName === 'inventorySettings') {
            localStorage.setItem('isAllowoutofStockOrder', i.values[0].isAllowoutofStockOrder);
          }
        })
      }
      return {
        ...state,
        themeSettings: action.payload,
        isAllowSelfRegistration: tempIsAllowSelfRegistration,
        isLPublic: tempLIsPublic,
      }
    case 'PRODUCT_SETTINGS':
      return {
        ...state,
        themeProductSettings: action.payload,
      }
    case 'CATEGORIES_LIST':
      return {
        ...state,
        categoriesList: action.payload,
      }
    case 'CATEGORIES_MENU_LIST':
      return {
        ...state,
        categoriesMenuList: action.payload,
      }
    case 'TAGS_LIST':
      return {
        ...state,
        tagsList: action.payload,
      }
    case 'STATIC_PAGES_LIST':
      return {
        ...state,
        staticPagesList: action.payload,
      }
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
        userType: action.payload.userType,
      }
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      }

    case 'ISLOADED':
      return {
        ...state,
        isFirstTime: false,
        loading: false,
      }

    case 'ISLOADEDTRUE':
      return {
        ...state,
        loading: true,
      }

    case 'FORLOADER':
      return {
        ...state,
        loading: action.payload,
      }
    case 'TAGS_VALUES':
      localStorage.setItem("tagsValues", JSON.stringify(action.payload))
      return {
        ...state,
        tagsValues: action.payload,
      }
      case 'CATEGORIES_USER_GROUP': 
        return {
          ...state,
          categoriesByUserGroup: action.payload,
        }
      case 'LOAD_CAT':
        return {
          ...state,
          loadingCategories: action.payload,
        }
      case 'USER_BUDGET':
        return {
          ...state,
          userBudget: action.payload,
        }
      case 'DEFAULT_USER_GROUP':
        return {
          ...state,
          defaultUserGroup: action.payload,
        }
      case 'PUBLIC_IP':
        return {
          ...state,
          ipV4: action.payload,
          ipV4Loaded: true,
        }
      case 'LOADING_CATEGORIES_USER_GROUP':
        return {
          ...state,
          loadingUserCategories: action.payload,
        }
      case 'FAVICON':
        return {
          ...state,
          favicon: action.payload,
        }
      case 'IMAGES':
        return {
          ...state,
          images: action.payload,
        }
      case 'BRAND-LOGO':
        return {
          ...state,
          logoData: action.payload,
        }
      case 'ARTWORKS_USER_GROUP':
        return {
          ...state,
          artworksByUserGroup: action.payload,
        }
      case 'USER_GROUP_DATA':
        return {
          ...state,
          userGroupsData: action.payload,
        }
      case 'GOOGLE_CAPTCHA_KEY':
        return {
          ...state,
          googleCaptchaKey: action.payload
        }
    default:
      return state
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState)
  const [countryCode, setCountryCode] = useState([])
  const requestOptions = {
    headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
  }
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : ''
  if(localStorage.apiToken) {
    localStorage.removeItem('apiToken')
  }
  if(user && user.apiToken) {
    delete user.apiToken;
    localStorage.setItem('user', JSON.stringify(user));
  }
  if (state.isFirstTime) {
    getPublicIp();
    dispatch({
      type: 'ISLOADED',
    })
    getClientLogo()
    axios
      .get(FAVICON_REQUEST, requestOptions)
      .then(res => {
        dispatch({
          type: 'FAVICON',
          payload: res.data
        })
      })
      .catch(error => console.log('error', error))
    axios
      .get(IMAGES_REQUEST, requestOptions)
      .then(res => {
        dispatch({
          type: 'IMAGES',
          payload: res.data
        })
      })
      .catch(error => console.log('error', error))
    axios
      .get(SETTINGS, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            dispatch({
              type: 'SETTINGS',
              payload: res.data,
            })

            res.data.map((i) => {
              if (i.page === 'General') {
                const currentUrl = 'https://' + window.location.hostname + 
                  (window.location.hostname === 'localhost' ? ":3000" : "")
                dispatch({
                  type: 'TAGS_VALUES',
                  payload: {
                    websiteName: i.values.tabName,
                    webStoreName: i.settingsName,
                    webStoreLink: currentUrl,
                    cartLink: currentUrl + "/cart",
                    userAccountLink: currentUrl + "/my-account",
                    userEmail: user?.emailAddress,
                  },
                })
                if (i.values.defaultCountry != undefined && i.values.defaultCountry != 'undefined' && i.values.defaultCountry != '') {
                  setCountryCode(i.values.defaultCountry)
                }
              }
              if (i.page === 'GoogleApiSettings') {
                const googleCaptchaKey = i.values?.captchaToken || null
                dispatch({
                  type: 'GOOGLE_CAPTCHA_KEY',
                  payload: googleCaptchaKey,
                })
              }
            });
            
          }
        }
        dispatch({
          type: 'ISLOADED',
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
    axios
      .get(PAYMENT_SETTING_URL, requestOptions)
      .then((res) => {
        if (res.data) {
          const pointSettings = res.data.find(pS => pS.settingsName === 'pointsSettings')
          if(pointSettings) {
            dispatch({
              type: 'POINT_CURRENCY_SETTINGS',
              payload: pointSettings.values[0] ?? {},
            })
          }
          dispatch({
            type: 'PAYMENT_SETTINGS',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
    if (user && user.userId) {
      axios
        .get(GET_USER_POINTS + user.userId, requestOptions)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              dispatch({
                type: 'USER_POINTS',
                payload: res.data,
              })
            }
          }
          dispatch({
            type: 'ISLOADED',
          })
        })
        .catch((err) => {
          console.log('err', err)
        })
      callUserBudget(user.userId);
    } else {
      axios
        .get(DEFAULT_USERGROUP, requestOptions)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              dispatch({
                type: 'DEFAULT_USER_GROUP',
                payload: res.data,
              })
              if(res.data?.categories?.length > 0) {
                let data = res.data.categories;
                data = data.filter((item, pos) => {
                  return data.indexOf(item) == pos;
                })
                localStorage.setItem('categoriesByUserGroup', JSON.stringify(data));
                dispatch({
                  type: 'CATEGORIES_USER_GROUP',
                  payload: data,
                })
              } else {
                localStorage.removeItem('categoriesByUserGroup');
              }
              getUserGroupArtworkLocations([res.data._id])
              dispatch({
                type: 'USERGROUPDISCOUNT',
                payload: res?.data?.productDiscount || 0,
              })
              dispatch({type: 'USER_GROUP_DATA', payload: [res.data]})
            }
          }
          dispatch({
            type: 'ISLOADED',
          })
        })
        .catch((err) => {
          console.log('err', err)
        })
    }

    if (user?.userGroups?.length > 0) {
      axios
        .post(
          GET_USERGROUP_DISCOUNT,
          { userGropsId: user.userGroups },
          requestOptions,
        )
        .then((res) => {
          dispatch({
            type: 'FORLOADER',
            payload: false,
          })
          if (res.data) {
            dispatch({
              type: 'USERGROUPDISCOUNT',
              payload: res?.data?.discount,
            })
          } else {
            dispatch({
              type: 'USERGROUPDISCOUNT',
              payload: '',
            })
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
        dispatch({
          type: 'LOADING_CATEGORIES_USER_GROUP',
          payload: false,
        })
      axios
        .post(
          GET_CATEGORIES_BY_USER_GROUPS,
          { userGroups: user.userGroups },
          requestOptions,
        )
        .then((res) => {
          if (res.data) {
            let data = [];
            res.data.forEach(userGroup => {
              data = data.concat(userGroup.categories);
            });
            data = data.filter((item, pos) => {
              return data.indexOf(item) == pos;
            })
            localStorage.setItem('categoriesByUserGroup', JSON.stringify(data));
            dispatch({
              type: 'CATEGORIES_USER_GROUP',
              payload: data,
            })
          } else {
            dispatch({
              type: 'CATEGORIES_USER_GROUP',
              payload: [],
            })
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => {
          dispatch({
            type: 'LOADING_CATEGORIES_USER_GROUP',
            payload: true,
          })
        })
      getUserGroupArtworkLocations(user.userGroups)
      axios.post(USER_GROUP_DATA, user.userGroups, requestOptions)
        .then(res => dispatch({type: 'USER_GROUP_DATA', payload: res.data}))
        .catch(error => console.log('error', error));
    } else {
      dispatch({
        type: 'LOADING_CATEGORIES_USER_GROUP',
        payload: true,
      })
      dispatch({
        type: 'CATEGORIES_USER_GROUP',
        payload: [],
      })
      localStorage.removeItem('categoriesByUserGroup');
    }

    let url = user.userId ? GET_BANNER + '/' + state.user.userId : GET_BANNER
    axios
      .get(url, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'BANNERLIST',
            payload: res.data,
          })
        }
        dispatch({
          type: 'ISLOADED',
        })
      })
      .catch((err) => {
        console.log('err', err)
      })

    axios
      .get(ACTIONGRID, requestOptions)
      .then((res) => {
        if (res.data) {
          dispatch({
            type: 'ACTIONGRIDLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })

    axios
      .get(IMAGESLIDER, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'IMAGESLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })

    axios
      .get(CATEGORYSLIDER, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'CATEGORYSLIDERLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })

    axios
      .get(TESTIMONIAL, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'TESTIMONIALLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })

    axios
      .get(PROMOTIONALPRODUCT_CAT, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'PROMOTIONALPRODUCTCATLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })

    axios
      .get(THEME_URL, requestOptions)
      .then((res) => {
        if (res.data.length) {
          if (res.data.length) {
            const productSettings = res.data.filter(
              (x) =>
                x.settingsName === 'inventorySettings' ||
                x.settingsName === 'productpageSettings',
            )
            dispatch({
              type: 'PRODUCT_SETTINGS',
              payload: productSettings,
            })
            dispatch({
              type: 'THEME_SETTINGS',
              payload: res.data,
            })
          }
        }
        dispatch({
          type: 'ISLOADED',
        })
      })
      .catch((err) => {
        console.log('err', err)
      })

    if (user.userGroups && user.userGroups.length > 0) {
      getUserGroupCategories()
    } else {
      axios
        .get(CATEGORIES_URL, requestOptions)
        .then((res) => {
          if (res.data) {
            if (res.data.length) {
              dispatch({
                type: 'CATEGORIES_LIST',
                payload: res.data,
              })
              dispatch({
                type: 'ISLOADED',
              })
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        }).finally(() => {
          dispatch({
            type: 'LOAD_CAT',
            payload: false,
          })
      })
    }

    axios
      .get(GET_TAGS_URL, requestOptions)
      .then((res) => {
        if (res.data) {
          if (res.data.length) {
            dispatch({
              type: 'TAGS_LIST',
              payload: res.data,
            })
          }
        }
        dispatch({
          type: 'ISLOADED',
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
    axios
      .get(STATIC_PAGE_URL, requestOptions)
      .then((res) => {
        if (res.data && res.data.length) {
          dispatch({
            type: 'STATIC_PAGES_LIST',
            payload: res.data,
          })
        }
        dispatch({
          type: 'ISLOADED',
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
    axios
      .get(
          ADVANCE_STORE_DETAILS,
          requestOptions,
      )
      .then((res) => {
        if (res.data) {
          let storeData = res.data
          if (storeData) {
            dispatch({
              type: 'STORE_DETAILS',
              payload: storeData,
            })
            dispatch({
              type: 'STORE_STATUS',
              payload: storeData.status,
            })
            localStorage.setItem('advanceApiurl', `https://${storeData.advanceurl}/protected`)
            localStorage.setItem(
              'storeDetails',
              JSON.stringify(storeData),
            )
          }
        }
      })
      .catch((err) => {
        console.log('err', err)
      })

    setTimeout(() => {
      getDecoTypeList()
    }, 1000)
  }
  function getUserGroupCategories() {
    let userCat = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : ''
    if(!userCat.userId) {
      let session = localStorage.getItem('-')
        ? JSON.parse(localStorage.getItem('-'))
        : '';
      if(!session) {
        getSession()
      }
      return;
    }
    if (userCat && userCat.userGroups && userCat.userGroups.length > 0) {
      let body = {
        userGroups: userCat.userGroups,
      }
      axios
          .post(CATEGORIES_USER_URL, body, requestOptions)
          .then((res) => {
            if (res.data) {
              dispatch({
                type: 'CATEGORIES_MENU_LIST',
                payload: res.data,
              })
              dispatch({
                type: 'CATEGORIES_LIST',
                payload: res.data,
              })
            }
            dispatch({
              type: 'ISLOADED',
            })
          })
          .catch((err) => {
            console.log('err', err)
          })
          .finally(() => {
            dispatch({
              type: 'LOAD_CAT',
              payload: false
            })
          })
    }
  }

  async function getUserGroupArtworkLocations(userGroups) {
    axios.post(ARTWORK_USER_GROUP, userGroups, requestOptions)
        .then(res => dispatch({type: 'ARTWORKS_USER_GROUP', payload: res.data}))
        .catch(error => console.log('error', error));
  }

  async function getPublicIp() {
    let ipV4 = await publicIp.v4();
    dispatch({
      type: 'PUBLIC_IP',
      payload: ipV4,
    })
  }

  const getSession = async () => {
    axios.get(SESSION, requestOptions).then(response => {
      localStorage.setItem('-', response.data);
    }).catch(error => {
      console.log(error)
    })
  }

  function getClientLogo() {
    axios.get(BRAND_LOGOS, requestOptions)
      .then((res) => {
        dispatch({
          type: 'BRAND-LOGO',
          payload: res.data
        })
      }).catch(err => {
        console.log('error', err);
      });
  }

  function login(user, isFirstTime = true) {
    if ( isFirstTime ) {
      //Store in memory and then remove it from user object
      localStorage.setItem('accessToken', user.accessToken)
      user.accessToken = '<No way!>'
      localStorage.setItem('user', JSON.stringify(user))
      if ( user.userGroups && user.userGroups.length > 0 ) {
        dispatch({
          type: 'LOAD_CAT',
          payload: true
        })
        getUserGroupCategories()
      }
      getUserGroupDiscount(user)
      updateUserIdCart(state.ipV4)
      updateUserIdWishlist(state.ipV4)
      dispatch({
        type: 'LOGIN',
        payload: user,
      })
      getUsersPoints(user)
      callUserBudget(user.userId)
      localStorage.removeItem("user_logout")
    }
  }

  function clanSession() {
    localStorage.removeItem('user')
    localStorage.removeItem('advancePaymentMethod')
    localStorage.removeItem('advanceApiurl')
    localStorage.removeItem('apiToken')
    localStorage.removeItem('googleApiToken')
    localStorage.removeItem('storeDetails')
    localStorage.removeItem('costCenter')
    localStorage.removeItem('categoriesByUserGroup');
    localStorage.removeItem('categoriesList');
    sessionStorage.removeItem("impersonateUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("viva-sessionId");
    localStorage.removeItem("viva-sessionIntervalId");
    localStorage.setItem("user_logout", '1');
    getAllBanner()
    cleanContactData()
  }

  function logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('advancePaymentMethod')
    localStorage.removeItem('advanceApiurl')
    localStorage.removeItem('apiToken')
    localStorage.removeItem('googleApiToken')
    localStorage.removeItem('storeDetails')
    localStorage.removeItem('costCenter')
    localStorage.removeItem('categoriesByUserGroup');
    localStorage.removeItem('categoriesList');
    sessionStorage.removeItem("impersonateUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("viva-sessionId");
    localStorage.removeItem("viva-sessionIntervalId");
    localStorage.setItem("user_logout", '1');
    getAllBanner()
    cleanContactData()
    dispatch({ type: 'LOGOUT' })

    dispatch({
      type: 'LOGIN',
      payload: '',
    })
    window.location.reload()
  }

  function updateUser(user) {
    dispatch({
      type: 'LOGIN',
      payload: user,
    })
    localStorage.setItem('user', JSON.stringify(user));
  }

  function getUserGroupDiscount(userParam = null) {
    const userData = userParam ? userParam: user
    if (userData?.userGroups?.length > 0) {
      axios
        .post(
          GET_USERGROUP_DISCOUNT,
          { userGropsId: userData.userGroups },
          requestOptions,
        )
        .then((res) => {
          dispatch({
            type: 'FORLOADER',
            payload: false,
          })
          if (res.data) {
            dispatch({
              type: 'USERGROUPDISCOUNT',
              payload: res?.data?.discount,
            })
          } else {
            dispatch({
              type: 'USERGROUPDISCOUNT',
              payload: '',
            })
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  function getAllBanner() {
    dispatch({
      type: 'FORLOADER',
      payload: true,
    })
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : ''
    let url = user?.userId ? GET_BANNER + '/' + user?.userId : GET_BANNER
    axios
      .get(url, requestOptions)
      .then((res) => {
        dispatch({
          type: 'FORLOADER',
          payload: false,
        })
        if (res.data.length) {
          dispatch({
            type: 'BANNERLIST',
            payload: res.data,
          })
        } else {
          dispatch({
            type: 'BANNERLIST',
            payload: '',
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  function getAllImages() {
    axios
      .get(IMAGESLIDER, requestOptions)
      .then((res) => {
        dispatch({
          type: 'FORLOADER',
          payload: false,
        })
        if (res.data.length) {
          dispatch({
            type: 'IMAGESLIST',
            payload: res.data,
          })
        } else {
          dispatch({
            type: 'IMAGESLIST',
            payload: '',
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  function getThemeSettings() {
    dispatch({
      type: 'ISLOADEDTRUE',
    })
    axios
      .get(THEME_URL, requestOptions)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            dispatch({
              type: 'THEME_SETTINGS',
              payload: res.data,
            })
          }
        }
        dispatch({
          type: 'ISLOADED',
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  function getActionGridDetails() {
    axios
      .get(ACTIONGRID, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'ACTIONGRIDLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  function getTestimonial() {
    axios
      .get(TESTIMONIAL, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'TESTIMONIALLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  function getFeaturedPromotionalProcutCat() {
    axios
      .get(PROMOTIONALPRODUCT_CAT, requestOptions)
      .then((res) => {
        if (res.data.length) {
          dispatch({
            type: 'PROMOTIONALPRODUCTCATLIST',
            payload: res.data,
          })
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  function getUserPoints() {
    if (user && user.userId) {
      axios
        .get(GET_USER_POINTS + user.userId, requestOptions)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              dispatch({
                type: 'USER_POINTS',
                payload: res.data,
              })
            }
          }
          dispatch({
            type: 'ISLOADED',
          })
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  function getUsersPoints(userData) {
    if (userData && userData.userId) {
      axios
        .get(GET_USER_POINTS + userData.userId, requestOptions)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              dispatch({
                type: 'USER_POINTS',
                payload: res.data,
              })
            }
          }
          dispatch({
            type: 'ISLOADED',
          })
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  useEffect(() => {
    getUserGroupCategories()
  },[])

  useEffect(() => {
    if(countryCode && countryCode.length > 0){
      getStates(countryCode)
    }
  }, [countryCode])

  function getStates(countryCode) {
    let data ={
      country:countryCode
    }
    Promise.resolve(ordersAPIUtil.fetchAllStates(data)).then((res) => {
      dispatch({
        type: 'STATELIST',
        payload: res.data,
      })
    }).catch((err) => {
      console.log('err', err)
    })
  }

  function getDecoTypeList() {
    Promise.resolve(productsApiUtil.getDecoratorTypes()).then((res) => {
      if (res.data.length) {
        dispatch({
          type: 'DECOTYPELIST',
          payload: res.data,
        })
      }
    }).catch((err) => {
      console.log('err', err)
    })
  }

  function callUserBudget(userId) {
    if(userId) {
      axios
        .get(GET_USER_BUDGET + userId, requestOptions)
        .then((res) => {
          if (res.data) {
            dispatch({
              type: 'USER_BUDGET',
              payload: res.data,
            })
          }
        })
        .catch((error) => {
          console.log('err', error)
        });
    }
  }

  return (
    <AuthContext.Provider
      value={{
        categoriesList: state.categoriesList,
        categoriesMenuList: state.categoriesMenuList,
        tagsList: state.tagsList,
        staticPagesList: state.staticPagesList,
        settingData: state.setting,
        metaData: state.metaData,
        themeSettingsData: state.themeSettings,
        themeProductSettingsData: state.themeProductSettings,
        bannersList: state.bannersList,
        userPoints: state.userPoints,
        paymentMethodsSettings: state.paymentMethodsSettings,
        user: state.user,
        isPublic: state.isPublic,
        isLPublic: state.isLPublic,
        enableLandingPage: state.enableLandingPage,
        landingPageRedirect: state.landingPageRedirect,
        showAddToCartOnCat: state.showAddToCartOnCat,
        showAddToCartOnProd: state.showAddToCartOnProd,
        isAllowSelfRegistration: state.isAllowSelfRegistration,
        isDirectToCart: state.isDirectToCart,
        tabName: state.tabName,
        storeDetails: state.storeDetails,
        storeStatus: state.storeStatus,
        actionGridList: state.actionGridList,
        decoTypeList: state.decoTypeList,
        stateList: state.stateList,
        testimonialList: state.testimonialList,
        promotionalProductCat: state.promotionalProductCat,
        imagesList: state.imagesList,
        categorySliderList: state.categorySliderList,
        loading: state.loading,
        loadingCategories: state.loadingCategories,
        userBudget: state.userBudget,
        defaultUserGroup: state.defaultUserGroup,
        login,
        logout,
        clanSession,
        getAllBanner,
        getAllImages,
        getActionGridDetails,
        getTestimonial,
        getThemeSettings,
        getFeaturedPromotionalProcutCat,
        getUserPoints,
        getUserGroupDiscount,
        callUserBudget,
        getClientLogo,
        updateUser,
        userType: state.userType,
        userGroupDiscount: state.userGroupDiscount,
        tagsValues: state.tagsValues,
        categoriesByUserGroup: state.categoriesByUserGroup,
        usePointsAsCurrency: state.usePointsAsCurrency,
        pointValue: state.pointValue,
        pointLabel: state.pointLabel,
        pointLabelUnit: state.pointLabelUnit,
        showDecimalPoints: state.showDecimalPoints,
        loadingUserCategories: state.loadingUserCategories,
        favicon: state.favicon,
        images: state.images,
        logoData: state.logoData,
        artworksByUserGroup: state.artworksByUserGroup,
        userGroupsData: state.userGroupsData,
        ipV4: state.ipV4,
        ipV4Loaded: state.ipV4Loaded,
        accessToken: accessToken,
        googleCaptchaKey: state.googleCaptchaKey,
      }}
      {...props}
    />
  )
}

export { AuthContext, AuthProvider }
