import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../hooks";

export interface CheckoutState {
    testResponse: string;
};

const initialState: CheckoutState = {
    testResponse: "oops"
};

export const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setTestResponse: (state, action) => {
            state.testResponse = action.payload;
        }
    }
});

export const { setTestResponse } = checkoutSlice.actions;

export const selectTestResponse = (state: RootState) => state.checkout.testResponse;

export default checkoutSlice.reducer;