import React, { useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectTestResponse, setTestResponse } from "../../redux/reducers/checkoutReducer";

export default function NewCheckout() {
  const dispatch = useAppDispatch();
  const testResponse = useAppSelector(selectTestResponse);

  useEffect(() => {
    axios.get("http://localhost:3001/test").then((response) => {
      console.log(response.data);
      dispatch(setTestResponse(response.data));
    });
  }, []);

  console.log(testResponse);

  return (
    <Stack>
      <Typography data-testid="test-comp" variant="h4">{testResponse}</Typography>
    </Stack>
  );
};